import React from "react"
import SeoHeaders from "../components/seo/SeoHeaders"


export default function Page({ data, location }) {

  return (
    <SeoHeaders location={location} keyword="Vermögensberater Kassel" />
  )
}

